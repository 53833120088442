<script setup lang="ts">
import Layout from "@/Layouts/Layout.vue"
import { Head, Link } from "@inertiajs/vue3"
import { provide } from "vue"
import ATag from "@/Components/Reusable/ATag.vue"

const props = defineProps<{
	highschoolCount: number
	collegeCount: number
	status?: string
}>()

provide("loginStatus", props.status)
</script>

<template>
	<Layout>
		<Head title="Welcome" />
		<section class="jumbotron flex items-center justify-end">
			<div class="m-40 flex max-w-2xl flex-col items-center gap-y-4">
				<div class="text-center text-5xl text-white drop-shadow-xl">
					ADVERTISE ON {{ highschoolCount.toLocaleString() }} HIGH SCHOOL AND
					{{ collegeCount.toLocaleString() }} COLLEGE STUDENT NEWS WEBSITES
				</div>
				<div>
					<Link href="/marketplace" class="btn btn-primary text-white" as="button"
						>Browse Sites</Link
					>
				</div>
			</div>
		</section>
		<section
			class="parallax hero py-32"
			style="background-image: url(/images/snosites_background.jpg)"
		>
			<div class="hero-overlay bg-opacity-0"></div>
			<div class="hero-content text-center text-neutral-content">
				<div class="">
					<h1 class="mb-5 text-6xl font-extralight">SNO Sites</h1>
					<p class="mb-5 text-xl font-bold">
						SNOAds.com is an exclusive advertising platform for SNO Sites member
						schools.
					</p>
				</div>
			</div>
		</section>
		<section class="mx-auto flex max-w-3xl flex-col items-center justify-end py-12 text-lg">
			<p class="">
				SNOAds.com is a straightforward, self-service platform that allows businesses to
				advertise on high school and college student news websites. By placing your ad
				through SNOAds.com, you'll engage with a valuable audience of students, their
				families, and school alumni, while also supporting the vital work of student
				journalists. It's a win-win.
			</p>
			<h2 class="mt-8 text-xl font-bold">Getting Started is Simple:</h2>
			<ol class="flex flex-col gap-y-2">
				<li>
					1) Find Your Audience: Begin by searching in the ad store for the student news
					websites where you want your ads to appear and add your desired ad spaces to
					your shopping cart.
				</li>
				<li>
					2) Secure Your Placement: After checking out and completing a secure credit card
					payment, upload your ad images and links for review. Since these are
					student-focused websites, we carefully review all ads and their associated
					websites to ensure content is appropriate for our audience. Need more
					information about our content policies? Check out our
					<ATag href="https://snoads.com/terms">Terms of Use</ATag>.
				</li>
				<li>
					3) Launch and Monitor: Once your ad is approved, it will go live, and you can
					log into your account at any time to track its performance.
				</li>
			</ol>
			<p class="mt-4">
				By default, your ad campaign will run for 30 days from the date of approval, but you
				can choose to have the ad renewed monthly up to a year. To manage renewals or make
				changes to your campaign, simply log back into your account.
			</p>
			<p class="mt-4">
				<strong>Need Help?</strong> Watch this
				<ATag
					href="https://support.snosites.com/hc/en-us/articles/4409238413723-Buying-an-Ad-on-SNOads-com"
					>support video</ATag
				>
				and review the online instructions. If you still have questions, feel free to
				<ATag href="mailto:support@snosites.com">contact us</ATag>.
			</p>
		</section>
	</Layout>
</template>
<style scoped>
.jumbotron {
	background-image: url(/images/header_background.jpeg);
	background-size: cover;
	height: 600px;
}

.parallax {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
</style>
